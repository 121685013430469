$action: #963;
$action-text: #fff;
$action-hilite: #c63;
$action-alpha50: #99663388;
$accent: #932432;

$navbar-bg: #fef6ec;
$navbar-border: #d8aba7;

$warning-bg: #3c1874;
$error-bg: #e32432;
$okay-bg: #19a974;
$gray-bg: #777;
$gray-bg-hilite: #999;
$gray-bg: #999;
$gray-bg-hilite: #aaa;

///
// color2: '#D8C5E5'
$hookColor: #c495e5;
// color2: '#CDD2FF
$incitingColor: #6173ff;
// C5F3C4
// color2: '#B4D5B'
$turnColor: #53bb53;
// FEFFBF
// color2: '#ECECC7'
$pinchColor: #ecec6e;
// color2: '#FCE0C2'
$midpointColor: #fcb569;

// TODO: search codebase for var(-- and reconcile
// create theme/button/layout test page for light/dark and dial in
// update in-code things using var(-- to use @embed (explore if this will work)

// --t-solidbg-light: white;
// --t-solidbg-light2: #f5f5f5;
// --t-solidbg-light-accent: #e5e6ea;
// --t-solidbg-dark-accent: #35363a;
// --t-solidbg-dark: #242526;
// --t-solidbg-dark2: #18191a;
// --t-transbg-light: rgba(255, 255, 255, 0.8);
// --t-transbg-dark: rgba(0, 0, 0, 0.7);

$bg-text: #ccc;
$bg-text2: #eee;
$bg: #18191a;
$bg2: #242526;
$bg2b: #2f3031;
$bg3: #3a3b3c;

$bg-text: #333;
$bg-text2: #111;
$bg: #fff;
$bg2: #f5f5f5;
$bg3: #e5e6ea;

$logo-bg: url('/assets/img/codex.svg');
$logo-bg: url('/assets/img/codex.svg');

$box-shadow-bg: 3px 3px 3px black;
$box-shadow-bg: 3px 3px 5px $action;

:root {
  --radius: 0.25rem;
  --nav-height: 3rem;
  --alpha-lighten80: rgba(255, 255, 255, 0.8);
  --alpha-lighten50: rgba(255, 255, 255, 0.5);
  --alpha-lighten25: rgba(255, 255, 255, 0.25);
  --alpha-lighten10: rgba(255, 255, 255, 0.1);
  --alpha-lighten15: rgba(255, 255, 255, 0.15);
  --alpha-lighten05: rgba(255, 255, 255, 0.05);
  --alpha-darken80: rgba(0, 0, 0, 0.8);
  --alpha-darken50: rgba(0, 0, 0, 0.5);
  --alpha-darken25: rgba(0, 0, 0, 0.25);
  --alpha-darken10: rgba(0, 0, 0, 0.1);
  --alpha-darken15: rgba(0, 0, 0, 0.15);
  --alpha-darken05: rgba(0, 0, 0, 0.05);

  // need to work through this into theme
  --error: #932432;
  --warning: #ffb700;
  --okay: #888;
}

// /* todo: remove -- these are from tachyons */
// @custom-media --breakpoint-not-small screen and (min-width: 30em);
// @custom-media --breakpoint-medium screen and (min-width: 30em) and (max-width: 60em);
// @custom-media --breakpoint-large screen and (min-width: 60em);

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html,
body {
  height: 100%;
}
body {
  display: flex;
  flex-direction: column;
  height: 100%;
  font-family: Eb Garamond;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  overscroll-behavior: none;
  overflow-y: scroll;
  :focus {
    outline: 0;
    box-shadow: none;
  }
  #root {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    .body {
      flex: 1 0 auto;
      margin-top: var(--nav-height);
    }
  }
}

a:visited,
.link:visited,
a,
.link {
  cursor: pointer;
  text-decoration: none;
  color: $action;
  &:hover {
    color: $action-hilite;
  }
  &:active {
    color: $accent;
  }
}
input::placeholder,
.react-select__placeholder {
  font-style: italic;
  color: gray;
}
input,
.input,
textarea {
  padding: 0.4rem;
}
input,
.input,
textarea,
.button,
button {
  border-radius: 0.5rem;
  border-style: solid;
  border-width: 1px;
}
label {
  display: block;
  // font-size: 0.75rem;
  font-weight: bold;
  // text-transform: uppercase;
}
ul.less {
  list-style: none;
  li::before {
    content: '\00b7';
    font-weight: 800;
    color: gray;
    display: inline-block;
    width: 1em;
    margin-left: -0.9em;
  }
}

////////////////////////////////////////////////////////////////////////////////
// <button - solid primary
// <button border -- inverted with border
// <button clear -- inverted clear, no border
// <button plain -- like clear, but a slight hilite, no border
// + border
// + disabled
// + primary (text is primary)
// + medium -- padding
// + large -- padding
button,
.button,
a.button,
button:visited,
.button:visited,
a.button:visited {
  outline: 0;
  border: 0;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  border: solid 2px transparent;
  &.medium {
    padding: 0.25rem 0.75rem 0.25rem 0.75rem;
  }
  &.large {
    padding: 0.5rem 1rem 0.5rem 1rem;
  }
}

////////////////////////////////////////////////////////////////////////////////
ol.bullet-circles {
  font-weight: 400;
  counter-reset: item;
  padding-left: 0;
  > li {
    position: relative;
    display: flex;
    align-items: center;
    counter-increment: item;
    list-style: none inside;
    margin-top: 0.5rem;
    font-weight: 400;
    margin-left: 2rem;
  }
  > li:before {
    position: absolute;
    top: 0;
    content: counter(item);
    // background-color: var(--need-to-replace);
    color: white;
    top: 0;
    margin-left: -2rem;
    margin-right: 1rem;
    border-radius: 50%;
    font-size: 12pt;
    font-weight: 600;
    height: 1.5rem;
    width: 1.5rem;
    min-height: 1.5rem;
    min-width: 1.5rem;
    display: block;
    text-align: center;
  }
}

////////////////////////////////////////////////////////////////////////////////
// theme common (no colors)
.hover-hilite-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
}
.heading,
label {
  // text-transform: uppercase;
  font-weight: 600;
}

.theme-base-flat {
  border: solid 1px transparent;
}
.bg-white {
  color: $bg-text;
  background-color: $bg;
}
.theme-frame {
  border-radius: 0.5rem;
}

.plot:hover.bg-point-hover {
  .hook,
  .resolution {
    background-color: $hookColor;
    transition: background-color 300ms ease-in-out;
  }
  .inciting,
  .climax {
    background-color: $incitingColor;
    transition: background-color 300ms ease-in-out;
  }
  .turn1,
  .turn3 {
    background-color: $turnColor;
    transition: background-color 300ms ease-in-out;
  }
  .pinch1,
  .pinch2 {
    background-color: $pinchColor;
    transition: background-color 300ms ease-in-out;
  }
  .midpoint {
    background-color: $midpointColor;
    transition: background-color 300ms ease-in-out;
  }
}

.plot.bg-point {
  .hook,
  .resolution {
    background-color: $hookColor;
  }
  .inciting,
  .climax {
    background-color: $incitingColor;
  }
  .turn1,
  .turn3 {
    background-color: $turnColor;
  }
  .pinch1,
  .pinch2 {
    background-color: $pinchColor;
  }
  .midpoint {
    background-color: $midpointColor;
  }
}

.plot.b-point {
  .hook,
  .resolution {
    border-left: solid 1px $hookColor;
  }
  .inciting,
  .climax {
    border-left: solid 1px $incitingColor;
  }
  .turn1,
  .turn3 {
    border-left: solid 1px $turnColor;
  }
  .pinch1,
  .pinch2 {
    border-left: solid 1px $pinchColor;
  }
  .midpoint {
    border-left: solid 1px $midpointColor;
  }
}

.plot:hover .inherit-bg {
  @extend .theme-bg;
}

.hover-outline,
.hover-outline-minimize,
.hover-outline-primary {
  border: solid 1px transparent;
}

////////////////////////////////////////////////////////////////////////////////
body {
  color: $bg-text;
  border-color: $gray-bg;

  button,
  .button,
  a.button,
  button:visited,
  .button:visited,
  a.button:visited {
    color: $action-text;
    background-color: $action;
    &:hover {
      background-color: $action-hilite;
    }
    &:active {
      background-color: $action-hilite;
      color: $action-text;
    }
    &:disabled,
    &.disabled {
      border-color: transparent;
      background-color: #ccc;
      color: $gray-bg;
    }

    // variant
    &.clear {
      background-color: transparent;
      border-color: transparent;
    }
    &.clear,
    &.plain {
      color: inherit;
      &:hover {
        // color: white;
        background-color: var(--alpha-darken10);
      }
      &:active {
        color: white;
        // background-color: $action;
      }
      &:disabled,
      &.disabled {
        background-color: #efefef;
        color: $gray-bg;
      }
    }
    // variant
    &.border {
      border: solid 2px;
      &:not(:hover) {
        color: $bg-text;
      }
      &:not(.primary) {
        border-color: gray;
      }
      background-color: var(--alpha-darken10);
      &.clear {
        background-color: transparent;
      }
      &:hover {
        border-color: $action-hilite;
        background-color: transparent;
        color: $action-hilite;
      }
      &:active {
        background-color: var(--alpha-darken10);
      }
      &:disabled,
      &.disabled {
        border-color: $gray-bg;
      }
    }
    &.primary {
      color: $action;
      &:not(.clear):not(.plain) {
        border-color: $action;
      }
      background-color: transparent;
      &:hover {
        background-color: $action;
        color: $action-text;
      }
    }
    &.plain {
      color: $bg-text;
      &:not(.clear) {
        background-color: var(--alpha-darken10);
      }
      &:hover {
        background-color: var(--alpha-darken25);
        &.primary {
          background-color: $action;
        }
      }
      &:active {
        color: $action-text;
        background-color: $action;
      }
    }
    &.green {
      background-color: #19a974;
      &.border {
        color: #19a974;
        &:not(.clear):not(.plain) {
          border-color: #19a974;
        }
        background-color: transparent;
      }
      &:hover {
        background-color: $action;
        color: $action-text;
      }
    }
    &.blue {
      background-color: #357edd;
      &.border {
        color: #357edd;
        &:not(.clear):not(.plain) {
          border-color: #357edd;
        }
        background-color: transparent;
      }
      &:hover {
        background-color: $action;
        color: $action-text;
      }
    }
    &.danger:hover {
      background-color: $error-bg;
      color: white;
    }
  }

  // navlink
  a,
  .link {
    color: $action;
    &:hover {
      color: $action-hilite;
    }
    &:active {
      color: $accent;
    }
  }

  .shadow {
    box-shadow: $box-shadow-bg;
  }

  .theme-bg {
    background-color: $bg2;
    color: $bg-text;
  }
  .theme-frame {
    @extend .shadow;
    @extend .theme-bg;
    // backdrop-filter: blur(12px);
  }
  .theme-b-primary {
    border-color: $bg-text;
  }
  .theme-b-shadow {
    border-color: #ddd;
  }
  .theme-b-hilite {
    border-color: var(--alpha-darken10);
  }
  .theme-b-minimize {
    border-color: #ccc;
  }
  .theme-fg-minimize {
    color: #aaa;
    a,
    .link {
      color: #baaccd;
    }
  }
  .theme-bg-minimize {
    background-color: var(--alpha-lighten50);
    color: $bg-text;
  }
  .theme-bg-hilite {
    background-color: var(--alpha-darken10);
  }
  .theme-fg-hilite {
    color: black;
  }
  .theme-tab {
    background-color: $bg3;
    &.active {
      background-color: $bg2;
    }
    &:hover {
      background-color: $bg;
    }
  }
  .theme-bg-flat {
    color: $bg-text;
    background-color: $bg2;
    input,
    .input,
    textarea {
      background-color: var(--alpha-darken05);
      &:focus {
        background-color: white;
      }
    }
  }
  .theme-bg-flat {
    background-color: $bg2;
  }
  .theme-bg-accent {
    color: $bg-text;
    background-color: $bg3;
  }
  // TODO: see if we can merge this w/others
  .theme-bg-alt {
    background-color: $action;
    color: $bg-text;
    a,
    .a {
      color: #222;
      &:hover {
        color: #555;
      }
    }
  }
  // do not nest navbar because menuitem inserts itself at the base
  // .navbar-bg,
  .navbar {
    border-bottom: solid 1px $navbar-border;
    height: var(--nav-height);
  }
  .navbar,
  .footer,
  .menu {
    background-color: $navbar-bg;
    font-weight: 600;
    color: $accent;
  }
  .footer {
    border-top: solid 1px $navbar-border;
    img.logo {
      content: $logo-bg;
    }
  }
  .menu {
    border: solid 1px $navbar-border;
    box-shadow: $box-shadow-bg;
  }

  a.navlink,
  .navlink,
  button.navlink {
    cursor: pointer;
    color: $action;
    &.active {
      color: $action;
    }
    &:hover {
      color: $action;
    }
    border-radius: 0.25rem;
  }

  .theme-base-flat {
    background-color: $bg;
  }
  textarea,
  .input,
  input {
    background-color: var(--alpha-darken10);
    color: $bg-text;
    border: solid 2px transparent;
    &:hover {
      border-color: $gray-bg-hilite;
    }
    &:focus {
      background-color: inherit;
      border-color: $action;
    }
  }
  .react-select__control:hover {
    border-color: $gray-bg-hilite;
  }
  .heading,
  .accent,
  label {
    color: $accent;
  }
  label.minimize {
    color: #333;
  }
  .primary {
    color: $action;
  }
  .text {
    color: $bg-text;
  }
  .error {
    color: $error-bg;
  }
  .error-bg {
    background-color: $error-bg;
    color: white;
  }
  .action {
    background-color: $action;
  }
  .b--primary {
    border-color: $action;
    stroke: $action;
  }
  .b--hilite {
    border-color: black;
    stroke: black;
  }
  .b--theme-faint {
    border-color: #ccc;
  }

  .theme-fade-layer {
    background-color: var(--alpha-lighten10);
  }
  .theme-fade-layer50 {
    background-color: var(--alpha-lighten50);
  }
  .theme-hilite-layer {
    background-color: var(--alpha-darken05);
  }
  .theme-hilite-layer2 {
    background-color: var(--alpha-darken25);
  }
  .theme-hilite-layer5 {
    background-color: var(--alpha-darken50);
  }
  .hover-hilite:hover {
    background-color: var(--alpha-darken10);
    fill: var(--alpha-darken25);
  }
  .hover-danger:hover {
    background-color: $error-bg;
    color: white;
  }
  .hover-text:hover {
    color: $bg-text2;
  }
  .hover-primary:hover {
    color: $action;
  }
  .hover-outline-primary:hover {
    border-color: $action;
  }
  .hover-outline:hover {
    border-color: #ccc;
  }
  .hover-outline-minimize:hover {
    border-color: #e9e9e9;
  }
  ol.bullet-circles > li:before {
    background-color: $action;
  }

  // classNamePrefix="react-select" is required with react-select for this to work
  .react-select__control {
    background-color: var(--alpha-darken05);
    color: $bg-text;
  }
  .react-select__option {
    &:hover {
      background-color: var(--alpha-darken10);
    }
    &:focus-within {
      background-color: red;
    }
  }
  .react-select__dropdownIndicator {
    color: $bg-text;
  }
}

//////////////////////////////////////////////////////////////////////////////
// END of theming

// // react-select is a real PITA
// .react-select__option {
//   padding: 0.5rem 0.4rem !important;
// }
// .react-select__control {
//   outline: none;
//   border-radius: 0.5rem;
//   border: solid 2px transparent;
//   min-height: auto;
//   padding: 0;
//   margin: 0;
//   align-items: center;
//   box-sizing: border-box;
//   cursor: default;
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: space-between;
//   min-height: 38;
//   position: relative;
//   transition: all 100ms;
// }
// .react-select__valueContainer {
//   padding: 0 !important;
//   min-height: auto !important;
// }
// .react-select__menu {
//   border-radius: 0 !important;
//   white-space: nowrap !important;
//   width: auto !important;
//   top: 2rem !important;
//   left: 0 !important;
//   padding: 0 !important;
//   min-width: 100% !important;
// }

h1,
h2,
h3,
h4 {
  margin: 0;
}

/* LOADER SPINNERS */
.loading-inline {
  margin-left: 1em;
  &.ml0 {
    margin-left: 0;
  }
  width: 1em;
  height: 1em;
  border-radius: 3em;
  animation: spin 1s linear infinite;
  border-top: 0.5em solid rgba(0, 0, 0, 0.2);
  border-right: 0.5em solid rgba(0, 0, 0, 0.2);
  border-bottom: 0.5em solid rgba(0, 0, 0, 0.2);
  border-left: 0.5em solid #ffffff;
}

/* loading spinner */
.loading-overlay {
  z-index: 2147483647;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(3px);
  background-color: rgba(0, 0, 0, 0.7);
}
.loading,
.loading-body {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 60vw;
  height: 60vw;
  margin: -30vw 0 0 -30vw;
  @media screen and (min-width: 30em) {
    width: 30vw;
    height: 30vw;
    margin: -15vw 0 0 -15vw;
  }
  @media screen and (min-width: 60em) {
    width: 10vw;
    height: 10vw;
    margin: -5vw 0 0 -5vw;
  }
}
.loading-body {
  color: white;
  text-align: center;
  top: 45%;
}
.loading {
  border-radius: 100%;
  border: 3px solid transparent;
  border-top-color: $action-alpha50; //  #4866ad;
  -webkit-animation: spin 5s linear infinite;
  animation: spin 5s linear infinite;
}
.loading:before {
  content: '';
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 100%;
  border: 3px solid transparent;
  border-top-color: $action; //  #8998c8;
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}
.loading:after {
  content: '';
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 100%;
  border: 3px solid transparent;
  // border-top-color: #b3bde0;
  border-top-color: $action-hilite;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-leave {
  opacity: 1;
}
.loading-leave.loading-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

/* */
.react-resizable {
  position: relative;
}
.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
  background-position: bottom right;
  padding: 0 3px 3px 0;
}
.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  transform: rotate(90deg);
}
.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}
.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  transform: rotate(180deg);
}
.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  transform: rotate(270deg);
}
.react-resizable-handle-w,
.react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize;
}
.react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}
.react-resizable-handle-e {
  right: 0;
  transform: rotate(315deg);
}
.react-resizable-handle-n,
.react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize;
}
.react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}
.react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}

/* hr with text in the middle -- hrstrike */
.hrstrike {
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
}
.hrstrike > div {
  position: relative;
  display: inline-block;
}
.hrstrike > div:before,
.hrstrike > div:after {
  content: '';
  position: absolute;
  top: 50%;
  width: 9999px;
  height: 1px;
  background-color: #999;
}
.hrstrike > div:before {
  right: 100%;
  margin-right: 15px;
}
.hrstrike > div:after {
  left: 100%;
  margin-left: 15px;
}
.blink {
  animation: 2s blink ease-in-out infinite;
}
.throb {
  animation: 2s throb ease-in-out infinite;
}
// keyframes
@keyframes fadein {
  from,
  to {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
}
@keyframes fadeout {
  from,
  to {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
@keyframes blink {
  from,
  to {
    color: inherit;
  }
  50% {
    color: transparent;
  }
}
@keyframes throb {
  from,
  to {
    color: inherit;
  }
  50% {
    color: rgba(255, 255, 255, 0.5);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// Future investigation.  http://css3.bradshawenterprises.com/cfimg/
// consider using img tags at a low layer
.background {
  // @media (orientation: portrait) {
  //   height: 100vh;
  //   width: auto;
  // }
  // @media (orientation: landscape) {
  //   height: auto;
  //   width: 100vw;
  // }
  // position: fixed;
  // left: 0;
  // right: 0;
  // top: 0;
  // bottom: 0;
  // z-index: -1;
  overflow: hidden;
  // animation: bgAnimate 20s linear infinite;

  // background: url(http://www.gstatic.com/webp/gallery/1.jpg) 0% 0% cover;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  max-height: inherit;
  background-position: center center;
  background-size: cover;
  margin: 0;
  /*
  -webkit-animation: animateBg 20s linear infinite;
  -webkit-animation-name: animateBg;
  -webkit-animation-duration: 100s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  */
  @media screen and (min-width: 30em) {
    &.img-pulse {
      animation: grow-shrink 60s ease-in-out infinite;
    }
  }
}

// scroll0h - all (no pad) except header/NavBar
// scroll1 - the entirety of the first level inner box
// scroll1h - first level box, except a top level header
// scroll2 - entirety of the second tier box
// scroll2h - second tier box except header
.scroller,
.scroll0,
.scroll0h,
.scroll1,
.scroll1h,
.scroll2,
.scroll2h {
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: transparent var(--alpha-darken50);
  &::-webkit-scrollbar,
  &::-webkit-scrollbar-track {
    width: 0.75rem;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--alpha-darken50);
    border-radius: 0.5rem;
  }
}
.scroll0 {
  height: calc(100vh - 1.7rem);
  max-height: calc(100vh - 1.7rem);
}
.scroll0h {
  height: calc(100vh - 1.75rem);
  max-height: calc(100vh - 1.75rem);
  @media screen and (min-height: 40em) {
    height: calc(100vh - 5.7rem);
    max-height: calc(100vh - 5.7rem);
  }
}
.scroll1 {
}
.scroll1h {
  height: calc(100vh - 1.7rem);
  max-height: calc(100vh - 1.7rem);
  @media screen and (min-height: 40em) and (max-height: 50em) {
    height: calc(100vh - 6.7rem);
    min-height: calc(100vh - 6.7rem);
  }
  @media screen and (min-height: 50em) {
    height: calc(100vh - 8.5rem);
    min-height: calc(100vh - 8.5rem);
  }
}
.scroll2 {
  height: calc(100vh - 3.8rem);
  max-height: calc(100vh - 3.8rem);
  @media screen and (min-height: 40em) and (max-height: 50em) {
    height: calc(100vh - 6.7rem);
    max-height: calc(100vh - 6.7rem);
  }
  @media screen and (min-height: 50em) {
    height: calc(100vh - 8.5rem);
    max-height: calc(100vh - 8.5rem);
  }
}
.scroll2h {
  height: calc(100vh - 5rem);
  max-height: calc(100vh - 5rem);
  @media screen and (min-height: 40em) and (max-height: 50em) {
    height: calc(100vh - 7.5rem);
    max-height: calc(100vh - 7.5rem);
  }
  @media screen and (min-height: 50em) {
    height: calc(100vh - 11.25rem);
    max-height: calc(100vh - 11.25rem);
  }
}

// .fader is the outer div, the elements you want faded are .fades
.fader {
  .fades {
    opacity: 0.1;
    transition: 0.3s;
    .hide,
    &.hide {
      opacity: 0;
    }
    .disappear,
    &.disappear {
      opacity: 0;
      transition: 0.3s;
      display: none;
    }
  }
  &:hover {
    .fades {
      opacity: 1;
      transition: 0.3s;
      display: inline;
    }
  }
}

/* basic expansions to tachyons */
.flex-start {
  display: flex;
  align-items: flex-start;
}
.flex-items {
  display: flex;
  // flex: 1 1 auto;
  align-items: center;
}
// flex-center
.flex-center {
  display: flex;
  // flex: 1 1 auto;
  align-items: center;
  justify-content: center;
}
// flex-between
.flex-between {
  display: flex;
  // flex: 1 1 auto;
  align-items: center;
  justify-content: space-between;
}
.middle {
  vertical-align: middle;
}
.top {
  vertical-align: top;
}
.top-center {
  top: 50%;
  transform: translateX(-50%);
}
.ofc {
  object-fit: cover;
}
.flex-wrap-2col-ns {
  @media screen and (min-width: 30em) {
    display: flex;
    flex-wrap: wrap;
    .elem {
      flex: 0 0 50%;
    }
  }
}
.hidden {
  overflow: hidden;
}

/* tools */
.react-switch-bg {
  background-color: gray;
}
.react-switch-handle {
}
.ReactModal__Body--open {
  // overflow-y: hidden;
}

// TODO: import tachyons and change the large breakpoint to be 70rem
.max-view-page {
  @media (min-width: 60rem) {
    // @media screen and (min-width: var(90rem)) {
    margin-left: auto;
    margin-right: auto;
    max-width: 55rem;
  }
}

table.altrows tr:nth-child(even) > td,
table.altrows tr:nth-child(even) > th {
  background-color: var(--alpha-darken10);
}
// table.list.hover tr:hover > td,
// table.list.hover tr:hover > th,
// table.list tr.hover:hover > td,
// table.list tr.hover:hover > th {
//   border-color: $action-hilite;
// }
table.list {
  border-spacing: 0;
  // border-collapse: collapse;
  td {
    border: solid 1px transparent;
  }
  tr {
    td:nth-child(1) {
      border-top-left-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
    }
    td:nth-last-child(1) {
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
    }
  }
  tr:hover {
    td {
      border-top-color: $action;
      border-bottom-color: $action;
    }
    td:nth-child(1) {
      border-left-color: $action;
    }
    td:nth-last-child(1) {
      border-right-color: $action;
    }
  }
  &.vtop {
    td,
    th {
      vertical-align: top;
    }
  }
}

table.display {
  border-spacing: 0;
  border-collapse: collapse;
  td,
  th {
    text-align: center;
    border-color: gray;
    padding: 1rem;
  }
  td.l,
  th.l {
    text-align: left;
  }
  td {
    border-style: solid;
    border-width: 1px;
  }
  th {
    font-weight: 400;
    border-style: solid;
    border-width: 1px;
    border-top: none;
  }
  th:nth-child(1),
  td:nth-child(1) {
    border-left: none;
  }
  th:nth-last-child(1),
  td:nth-last-child(1) {
    border-right: none;
  }
}

.theme-frame-tabbed {
  @extend .theme-frame;
  border-top-left-radius: 0;
}

.z-plus-one {
  z-index: +1;
}

// .fruits {
//   display: flex;
//   padding-inline-start: 0;
//   list-style: none;
// }
//
// .fruit {
//   display: none; /* hidden by default */
// }
// .fruit.on { /* JavaScript-added class to reveal list items */
//   display: inline-block;
// }
// .fruit.on ~ .fruit.on::before {
//   content: ', ';
// }

.comma:not(:empty) ~ .comma:not(:empty):before {
  content: ', ';
}

.commas {
  :not(:nth-last-child(1)) {
    margin-right: 0.5rem;
    &:after {
      content: ',     ';
    }
  }
}

.commalist {
  .elem + .elem:last-child {
    &::before {
      content: ', and ';
    }
  }
  .elem:nth-of-type(n + 2):not(:last-child) {
    &::before {
      content: ', ';
    }
  }
}

//
.ba-status {
  border-width: 2px;
  border-style: dotted;
  &.b--status-good {
    border-color: transparent;
  }
  &.b--status-error,
  &.b--status-undefined {
    border-color: var(--error);
  }
  &.b--status-warning {
    border-color: var(--warning);
  }
  &.b--status-disable {
    border-color: gray;
  }
}

.upright {
  text-orientation: upright;
}

.b--error {
  border-color: var(--error);
}
.b--warning {
  border-color: var(--warning);
}

.warning {
  color: var(--warning) !important;
}
// .error {
//   color: var(--error) !important;
// }

.error-box {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 15rem;
  z-index: 2147483647;
  transform: translate(-50%, -50%);
  background-color: #ff725c;
  color: black;
  border-radius: 0.2rem;
  padding: 0.5rem;
}

.ok-box {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 15rem;
  z-index: 2147483647;
  transform: translate(-50%, -50%);
  font-size: 1.5rem;
  color: #19a974;
  text-align: center;
  opacity: 0;
  animation: fadeOut 2s ease-out;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.pill {
  text-align: center;
  padding: 0.125rem 0.5rem;
  @extend .theme-b-primary;
  border-style: solid;
  border-width: 1px;
  border-radius: 2rem;
  &.plain {
    border: none;
    @extend .theme-bg-hilite;
  }
}

.no-p > p {
  margin-top: inherit;
  margin-bottom: inherit;
}

.input-inner {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  // color: rgba(255, 255, 255, 0.25);
  // border-radius: 2rem;
  // &:hover {
  //   color: black;
  //   background: rgba(255, 255, 255, 0.5);
  // }
}

div.rawEditable {
  border: solid red 1px;
  width: 30rem;
  height: 100%;
  clear: both;
}
// textarea.stylish, textarea.stylish::before, textarea.stylish::after {
//   box-sizing: border-box;
// }
textarea.rawEditable {
  display: block;
  resize: none;
  outline: none;
  border-radius: 0;
  border: solid 1px green;
  margin: 0 !important;
  padding: 0.5rem !important;
  background: inherit !important;
  width: 100%;
  height: auto;
  overflow: hidden;
  // box-sizing: border-box;
}

.transparent {
  color: transparent;
}
