.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  // background: url('/codex/assets/stucco.webp');
}
.navlink {
  border: none;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  outline: none;
  font-weight: normal;
  background-color: transparent;
  padding: 0.25rem;
  min-width: 3rem;
}
.microPic {
  display: inline-block;
  position: relative;
  $my-size: 1.5rem;
  height: $my-size;
  width: $my-size;
  min-height: $my-size;
  max-height: $my-size;
  max-width: $my-size;
  border-radius: $my-size;
  overflow: hidden;
  img,
  i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    height: auto;
    width: $my-size;
  }
}
